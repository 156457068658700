<template>
  <div>
    <v-row>
      <v-col cols="12" md="10" lg="8">
        <common-card elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="2">
                <v-responsive
                  :aspect-ratio="1 / 1"
                  class="align-center"
                  :width="$vuetify.breakpoint.mdAndUp ? '100%' : '50%'"
                  style="margin: 0 auto;"
                >
                  <v-img :src="logo" contain></v-img>
                </v-responsive>
              </v-col>
              <v-col cols="12" md="5">
                <div>
                  <span class="subtitulo-header-2 font-montserrat"
                    >Tipo de plan</span
                  >
                  <v-select
                    v-model="selectedCotizacion"
                    :items="planes"
                    item-text="PlanNombreInter"
                    item-value="PlanCodigo"
                    outlined
                    class="mt-2"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>

        <div class="mt-5">
          <v-row class="mt-2">
            <v-col cols="12" md="8">
              <common-card elevation="0">
                <v-card-text
                  v-for="cobertura in currentCotizacion.PlanCoberturas"
                  :key="cobertura.CoberturaNombre"
                >
                  <v-row>
                    <v-col cols="1">
                      <v-img
                        :src="icons[cobertura.CoberturaNombre]"
                        max-width="25"
                        healt=""
                      >
                      </v-img>
                    </v-col>
                    <v-col cols="11" class="d-flex flex-column no-wrap">
                      <div style="width: 100%;">
                        <span class="subtitulo-header-2 font-montserrat">{{
                          cobertura.CoberturaNombre
                        }}</span>
                      </div>
                      <div>
                        <span class="text-body-1">
                          {{ cobertura.Descripcion }}</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </common-card>
            </v-col>
            <v-col cols="12" md="4">
              <common-card
                elevation="0"
                style="background-color: rgba(192, 219, 226, 0.50); width: 100%; height: 100%;"
                class="d-flex align-center py-2"
              >
                <div style="text-align: center; width: 100%;">
                  <div style="width: 100%">
                    <span class="text-cobeturaanual-title">
                      COBERTURA ANUAL
                    </span>
                  </div>
                  <div style="width: 100%" class="">
                    <span class="text-cobeturaanual-const">
                      {{ getPlanSumaAsegurada() }} MXN
                    </span>
                  </div>
                </div>
              </common-card>
            </v-col>
          </v-row>
        </div>
        <div class="mt-5">
          <v-row>
            <div class="card-content-attendance"></div>
          </v-row>
        </div>
        <div class="mt-5">
          <v-row
            v-for="cobertura in currentCotizacion.PlanAsistencias"
            :key="cobertura.AsistenciaNombre"
            class="mt-2"
          >
            <v-col cols="12" md="8">
              <common-card elevation="0">
                <v-card-text>
                  <v-row>
                    <v-col cols="1">
                      <v-img
                        :src="iconsAsistencias[cobertura.AsistenciaNombre]"
                        max-width="25"
                        healt=""
                      >
                      </v-img>
                    </v-col>
                    <v-col
                      cols="10"
                      class="d-flex flex-column align-center no-wrap"
                    >
                      <div style="width: 100%;">
                        <span class="subtitulo-header-2 font-montserrat">{{
                          cobertura.AsistenciaNombre
                        }}</span>
                      </div>
                      <div>
                        <span class="text-body-1">
                          {{ cobertura.Descripcion }}</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </common-card>
            </v-col>
            <v-col cols="12" md="4">
              <common-card
                elevation="0"
                style="background-color:#c0e6f2; width: 100%; height: 100%;"
                class="d-flex align-center py-2"
              >
                <div
                  style="text-align: center; width: 100%;"
                  v-for="precio in cobertura.Precios"
                  :key="precio"
                >
                  <div>
                    <div style="width: 100%">
                      <span class="text-cobeturaanual-title">
                        {{ precio.Nombre }}
                      </span>
                    </div>
                    <div style="width: 100%" class="">
                      <span class="text-cobeturaanual-const">
                        ${{ precio.Total }}
                      </span>
                    </div>
                  </div>
                </div>
              </common-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-row>
          <v-col cols="12">
            <common-card>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <span class="subtitulo-header-2 font-montserrat"
                      >Cotización</span
                    >
                  </v-col>
                  <v-col cols="6">
                    <span class="subtitulo-header-2 font-montserrat"
                      >#{{ cotizacionGeneral }}</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </common-card>
          </v-col>
          <v-col cols="12">
            <common-card>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <span class="font-montserrat">
                      Plan
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="subtitulo-header-2 font-montserrat">
                      {{ currentCotizacion.PlanNombreInter }}
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="font-montserrat">
                      Total
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="subtitulo-header-2 font-montserrat">
                      {{ currencyFormatt(currentCotizacion.PlanPrimaTotal) }}
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="font-montserrat">
                      Modalidad de pago
                    </span>
                  </v-col>
                  <v-col cols="6">
                    <span class="subtitulo-header-2 font-montserrat">
                      De contado
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      dark
                      class="common-botton"
                      @click="continueToEmission()"
                    >
                      <span>Emitir</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      dark
                      rounded
                      outlined
                      color="#00a7e4"
                      @click="$router.go(-1)"
                    >
                      <span>Regresar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </common-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import notes from "@/assets/notes.svg";
import { getCotizacionesByFiltros } from "@/services/cotizaciones/cotizaciones.service";

export default {
  components: {
    CommonCard,
  },

  data() {
    return {
      logo:
        "https://todoseguros.com.mx/wp-content/uploads/2021/06/Seguros-GMX.jpg",
      cotizacionMascotaDetail: {},
      selectedCotizacion: "",
      notesImg: notes,
      icons: {
        "Accidentes y enfermedades":
          "https://mascotasegura.inter.mx/static/media/icono-accidentesyenfermedades.0aeabd059fab0f2b62c3ffdbdbdd7264.svg",
        "Daños y travesuras":
          "https://mascotasegura.inter.mx/static/media/icono-da%C3%B1osytravesuras.77a49c3a260a25adb260b7c745340104.svg",
        "Gastos funerarios":
          "https://mascotasegura.inter.mx/static/media/icono-gastosfunerarios.d1ba96cc9a4d3cc6fe000c5d17ec3055.svg",
        Eutanasia:
          "https://mascotasegura.inter.mx/static/media/icono-eutanasia.3c77ceabe8cc12276fa042588862cddd.svg",
      },
      iconsAsistencias: {
        "Vacuna antirrábica o desparasitación":
          "https://mascotasegura.inter.mx/static/media/icono-vacuna.f75ab23829038403b395dce43d0d1fd8.svg",
        "Hospedaje por si te hospitalizan":
          "https://mascotasegura.inter.mx/static/media/icono-hospedaje.08e959754ea39a445048b2ca5e66e33c.svg",
        Baño:
          "https://mascotasegura.inter.mx/static/media/icono-ba%C3%B1o.696b78a7df1dbc5dbd06cdad8fdc6962.svg",
        Estética:
          "https://mascotasegura.inter.mx/static/media/icono-estetica.d6826eff5327549191fec7fa517b8741.svg",
      },
      responseAseguradora:[],
      planes:[]
    };
  },

  methods: {
    getPlanSumaAsegurada() {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.currentCotizacion.PlanSumaAsegurada);
    },
    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });
      return formatoMonedaMXN.format(val);
    },
    async getCotizacion(){
      this.responseAseguradora = await getCotizacionesByFiltros({
        grouped:true,
        cotizacion_general:this.$route.params.cotizacionGeneral
      })
      this.planes = JSON.parse(this.responseAseguradora[0].response)
    },
    continueToEmission(){
      this.$router.push(`/mascota/emision/details/${this.cotizacionGeneral}/${this.selectedCotizacion}`)
    }
  },
  computed: {
    currentCotizacion() { 
      var coberturas = [];
      if (this.planes.length > 0 && this.selectedCotizacion) {
        coberturas = this.planes.find(
          (e) => e.PlanCodigo == this.selectedCotizacion
        );
      }
      return coberturas;
    },
  },
  mounted() {
    this.getCotizacion()
    this.cotizacionMascotaDetail = {}
    this.selectedCotizacion = this.$route.params.planID;
    this.cotizacionGeneral = this.$route.params.cotizacionGeneral
  },
};
</script>

<style>
.card-content-attendance,
.card-content-attendance2 {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  justify-content: center !important;
  padding-top: 10px;
}

.card-content-attendance {
  background-image: url(https://mascotasegura.inter.mx/static/media/arrrow-destok-yellow.49c968a46f2376ebbc70.svg) !important;
  height: 50px !important;
  margin-left: 2px !important;
  width: 509px !important;
}
</style>
