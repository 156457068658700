import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getCotizacionesByFiltros = async (filtros) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.post(
    "/v1/cotizacion/filtros",
    filtros,
    config
  );
  return serverResponse.data

};

